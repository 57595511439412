import * as React from "react"
import { graphql } from "gatsby"
import ProblemSolutionSection, {ProblemSolutionSectionItem} from "../../components/sections/ProblemSolutionSection"
import { PrismicRichText } from "@prismicio/react"
import { normalizeImageProps } from "../../utils/image"

const PrismicPageDataBodyProblemSolution = ({ items, primary }) => {
  const problemSolutionItems = items.map((item): ProblemSolutionSectionItem => {
    return {
      icon: normalizeImageProps(item?.icon),
      problemTitle: item?.problem_title,
      problemDescription: <PrismicRichText field={item?.problem_text?.richText} />,
      solutionTitle: item?.solution_title,
      solutionDescription: <PrismicRichText field={item?.solution_text?.richText} />
    }
  })

  const title = <PrismicRichText field={primary?.title1?.richText} />

  return (
    <ProblemSolutionSection title={title} items={problemSolutionItems} />
  )
}
export default PrismicPageDataBodyProblemSolution

export const query = graphql`
    fragment ProblemSolution on PrismicPageDataBodyProblemSolution{
        slice_type
        slice_label
        __typename
        primary{
          title1{
            html
            richText
          }
        }
        items{
          icon{
            alt
            gatsbyImageData(layout: FIXED, width: 64, height: 64)
          }
          problem_title
          solution_title
            problem_text{
                html
                richText
            }
            solution_text{
                html
                richText
            }
        }
    }
`
