import * as React from "react"
import { graphql } from "gatsby"
import InsightCalloutSection from "../../components/sections/InsightCalloutSection"
import { PrismicRichText } from "@prismicio/react"
import { normalizeImageProps } from "../../utils/image"

const PrismicPageDataBodyInsightCallout = ({ primary }) => {
  const { 
    tags, 
    url,  
    tighten_vertical_spacing,
    document: {
      data: {
        intro_text,
        title,
        thumbnail_image,
      }
    }
  } = primary.insight
  const description = <PrismicRichText field={intro_text?.richText} />
  const image = normalizeImageProps(thumbnail_image)

  return (
    <InsightCalloutSection 
      title={title}
      description={description}
      image={image}
      overline={tags?.[0]}
      link={url}
      removeVerticalSpacing={primary?.tighten_vertical_spacing}
    />
  )
}

export default PrismicPageDataBodyInsightCallout

export const query = graphql`
    fragment InsightCallout on PrismicPageDataBodyInsightCallout {
        __typename
        id
        slice_label
        slice_type
        primary {
          tighten_vertical_spacing
          insight {
            tags
            url
            document {
              ... on PrismicInsight {
                id
                data {
                  intro_text {
                    richText
                  }
                  title
                  thumbnail_image {
                    alt
                    gatsbyImageData(layout: CONSTRAINED, width: 80)
                  }
                }
              }
            }
          }
        }
    }
`
