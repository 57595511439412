import styled, { css } from "styled-components"
import Link from "../Link"

export const linkStyles = css`
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #4DA893;
  }
`

export const buttonBaseStyles = css`
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  margin-right: auto;
  border: none;
  line-height: 120%;
  transition: 0.2s;


  &:hover {
    text-decoration: none;
    color: #008264;
  }

  &[type=submit], &[type=Reset] {
    -webkit-appearance: none;
  }
`

export const primaryStyles = css`
  ${buttonBaseStyles};
  background-color: ${({ theme }) => theme.primaryColor};
  color: #fff;
  padding: 1rem 3rem;
  border-radius: var(--roundness);
  font-size: 18px;

  &:hover {
    background-color: var(--tertiary-color);
    color: #fff;
  }

  &:disabled {
    background: #E9E9E9;
    color: #BEBEBE;
    cursor: not-allowed;
  }
`

export const secondaryStyles = css`
  ${buttonBaseStyles};
  background-color: transparent;
  color: var(--body-text-color-secondary);
  border-bottom: 5px solid ${({ theme }) => theme.secondaryColor};
  text-align: left;
  display: inline-block;
  font-size: 28px;

  &:hover {
    color: var(--primary-color);
  }
`

export const outlineStyles = css`
  ${buttonBaseStyles};
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  padding: 1rem 3rem;
  border-radius: var(--roundness);
  font-size: 18px;
  text-align: center;

  &:hover {
    color: var(--primary-color);
  }
`

export const invertedStyles = css`
  ${buttonBaseStyles};
  ${primaryStyles};
  background-color: #fff;
  color: var(--primary-color);
`


const Button = styled(Link)`
  transition: 0.2s;
  
  &:hover {
    color: #4DA893;
  }
  
  ${({ primary, isLink, theme, secondary, outline, inverted }) => {
    if (isLink) {
      return linkStyles
    }

    if (primary) {
      return primaryStyles
    }

    if (secondary) {
      return secondaryStyles
    }

    if (outline) {
      return outlineStyles
    }

    if(inverted){
      return invertedStyles
    }
  }}
`

Button.styled = {
  base: buttonBaseStyles,
  primary: primaryStyles,
  secondary: secondaryStyles,
  button: Button
}

export default Button
