import Button from "../../atoms/Button"
import { Composition } from "atomic-layout"
import * as React from "react"
import styled from "styled-components"

export interface TestimonialV2Props {
  name?: string,
  title?: string,
  highlight?: string,
  text?: React.ReactNode,
  button_url?: string,
  button_text?: string
}

const StyledHighlight = styled.div`
  color: #F0C44D;
  font-size: 64px;
  font-style: italic;
  margin-bottom: 32px;
`

const StyledText = styled.div`
  margin-bottom: 40px;
  font-size: 28px;
  line-height: 140%;
  color: #fff;

  p:first-of-type{
    position: relative;

    &:before{
      content: "“";
      color: rgba(178, 170, 171, 0.20);
      font-size: 350px;
      font-weight: 400;
      line-height: 1;
      position: absolute;
      top: -50px;
      left: -50px;
    }
  }

  p{
    b, strong{
      color: #F0C44D!important;
    }
  }
`

const StyledName = styled.div`
  color: #ECEAEA;
  font-size: 18px;
  line-height: 155%;
`

const StyledTitle = styled.div`
  color: #B2AAAB;
  font-size: 18px;
  line-height: 155%;
`

const TestimonialV2 = ({text = null, title = "", name = "", highlight = "", button_url = "", button_text = ""}: TestimonialV2Props) => (
    <Composition
      areas={`
        Highlight 
        Text
      `}
      areasLg={`Highlight Text`}
      templateCols={'1fr'}
      templateColsLg={'1fr 1fr'}
      templateRows={'auto'}
      gapCol={"3rem"}
    >
      {(Areas) => (
        <>
          <Areas.Highlight>
            <StyledHighlight>{highlight}</StyledHighlight>
            {button_url && button_text && <Button outline to={button_url}>{button_text}</Button>}
          </Areas.Highlight>
          <Areas.Text>
            <StyledText>{text}</StyledText>
            <StyledName>{name}</StyledName>
            <StyledTitle>{title}</StyledTitle>
          </Areas.Text>
        </>
      )}
    </Composition>
)

export default TestimonialV2
