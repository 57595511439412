import { CarouselProvider, DotGroup, Slide, Slider } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import * as React from "react"
import { render } from "react-dom"
import styled from "styled-components"
import Container from "../../atoms/Container"
import TestimonialV2, { TestimonialV2Props } from "../../organisms/TestimonialV2"
import Background from "../../molecules/Background"

const StyledDots = styled.div`
  .carousel__dot-group {
    text-align: center;
  }

  .carousel__dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #B2AAAB;
    background-color: transparent;
    margin-right: 24px;
    margin-top: 80px;

    &:last-child {
      margin-right: 0;
    }

    &.carousel__dot--selected {
      background-color: #99CDC1;
    }
  }
`

const TestimonialV2Section = (testimonial: TestimonialV2Props) => {

  
  return (
    <Container background={<Background color={"#3E2B2E"} />}>
      <TestimonialV2 {...testimonial} />
    </Container>
  )
}

export default TestimonialV2Section
