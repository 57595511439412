import * as React from "react"
import { graphql } from "gatsby"
import YellowSection from "../../components/sections/YellowSection"
import { PrismicRichText } from "@prismicio/react"
import { normalizeImageProps } from "../../utils/image"

const PrismicPageDataBodyYellowCallout = ({ primary }) => {
  const overline = primary?.overline
  const title = primary?.titlestring
  const description = primary?.description
  const icon = normalizeImageProps(primary?.icon)
  const ctaText = primary?.cta_text
  const ctaUrl = primary?.cta_url?.url || ""
  const tightenVerticalSpacing = primary?.tighten_vertical_spacing

  return (
    <YellowSection 
      overline={overline}
      title={title}
      description={description}
      icon={icon}
      ctaText={ctaText}
      ctaUrl={ctaUrl}
      removeVerticalSpacing={tightenVerticalSpacing}
    />
  )
}

export default PrismicPageDataBodyYellowCallout

export const query = graphql`
    fragment YellowSection on PrismicPageDataBodyYellowCallout {
        __typename
        id
        slice_label
        slice_type
        primary {
          overline
          titlestring: title1
          description
          icon {
            alt
            gatsbyImageData(layout: FIXED, width: 64, height: 64)
          }
          cta_text
          cta_url {
            url
            target
          }
          tighten_vertical_spacing
        }
    }
`
