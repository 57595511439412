import React from "react"
import Container from "../../atoms/Container"
import styled from "styled-components"
import Button from "../../atoms/Button"
import { Composition } from "atomic-layout"
import { GatsbyImage } from "gatsby-plugin-image"
import {gap} from "../../../utils/spacing"

export interface ProblemSolutionSectionProps {
  title: React.ReactNode
  items: ProblemSolutionSectionItem[]
}

export interface ProblemSolutionSectionItem {
  icon?: GatsbyImageProps
  problemTitle: string
  problemDescription: React.ReactNode
  solutionTitle: string
  solutionDescription: React.ReactNode
}

const StyledContainer = styled(Container)`
  p:first-of-type{
    margin-top: 0;    
  }

  p{
    font-size: 18px;
  }
`

const StyledIcon = styled.div`
  margin-top: 25px;
`

const StyledProblem = styled.div`
  padding: 16px;
`

const StyledSolution = styled.div`
  background-color: #F7F6F7;
  padding: 16px;
`

const ProblemSolutionSection = ({
  title,
  items
}: ProblemSolutionSectionProps) => {
  return (
    <StyledContainer>
      {title}
      {items.map((item, index) => (
        <Composition
        key={`problem-solution-${index}`}
        areas={`
          Icon
          Problem
          Solution
        `}
        areasLg={`Icon Problem Solution`}
        templateCols={'1fr'}
        templateColsLg={'64px 1fr 1fr'}
        templateRows={'auto'}
        gap={gap}
        style={{marginBottom: "4rem"}}
      >
        {(Areas) => (
        <>
          <Areas.Icon>
            <StyledIcon>
              {item.icon && <GatsbyImage {...item.icon} />}
            </StyledIcon>
          </Areas.Icon>
          <Areas.Problem>
            <StyledProblem>
              <h5>{item.problemTitle}</h5>
              {item.problemDescription}
            </StyledProblem>
          </Areas.Problem>
          <Areas.Solution>
            <StyledSolution>
              <h5>{item.solutionTitle}</h5>
              {item.solutionDescription}
            </StyledSolution>
          </Areas.Solution>
        </>
      )}
          
        </Composition>
      ))}
    </StyledContainer>
  )
}

export default ProblemSolutionSection
