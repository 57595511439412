import * as React from "react"
import { graphql } from "gatsby"
import InnerHTML from 'dangerously-set-html-content'

const PrismicInsightDataBodyRawHtml = ({ primary }) => {

  return (
    <InnerHTML html={primary.html} />
  )
}
export default PrismicInsightDataBodyRawHtml

export const query = graphql`
    fragment InsightRawHTML on PrismicInsightDataBodyRawHtml {
      slice_type
      slice_label
      __typename
      id
        primary {
            html
        }
    }
`
