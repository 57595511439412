import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../layout"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import SliceRenderer from "../slices"
import Container from "../components/atoms/Container"
import parse from "html-react-parser"
import { PrismicNavigation } from "../layout/PrismicNavigation"
import PrismicFooter from "../layout/PrismicFooter"
import { GatsbyImage } from "gatsby-plugin-image"
import PrevNext from "../components/molecules/PrevNext"
import { normalizeImageProps } from "../utils/image"
import { videoId } from "../components/sections/VideoSection"
import LiteYouTubeEmbed from "react-lite-youtube-embed"
import CalloutSection from "../components/sections/CalloutSection"
import TextSection from "../components/sections/TextSection"

const Insight = ({ data, pageContext: { previous = {}, next = {} } }) => {
  const intro = data?.prismicInsight?.data?.intro_text?.html
  const image = normalizeImageProps(data?.prismicInsight?.data?.featured_image)
  const video_id = videoId(data?.prismicInsight?.data?.featured_video)
  const publishedDate = data?.prismicInsight?.data?.published_date
  const author = data?.prismicInsight?.data?.author?.document?.data?.name
  var options = { year: "numeric", month: "long", day: "numeric" }

  return (
    <Layout>
      <PrismicNavigation />

      <Container innerProps={{ marginTop: "2rem", marginBottom: "calc(var(--section-spacing) / 4)" }} fluid>
        {video_id && <LiteYouTubeEmbed
          title={"video"}
          id={video_id}
          params={"rel=0&modestbranding=1"}
          poster={"maxresdefault"}
        />}
        {image?.image &&
          <GatsbyImage {...image} />
        }
      </Container>

      <Container
        removeVerticalSpacing={true}
        innerProps={{ style: { textAlign: "center", marginBottom: "calc(var(--section-spacing) / -2)" } }}
        small
      >
        {(author || publishedDate) &&
          <div style={{ marginBottom: "var(--section-side-padding)" }}>
            {publishedDate &&
              <span>{(new Date(publishedDate)).toLocaleDateString("en-US", options)} | </span>
            }
            {author &&
              <span>Written by {author}</span>
            }
          </div>
        }
        <h1>{data?.prismicInsight?.data?.title}</h1>
        {intro &&
          parse(intro)
        }
      </Container>

      <SliceRenderer doc={data?.prismicInsight} />

      <TextSection
        buttonText={'Book a Discovery Call'}
        buttonUrl={{url: '/book-meeting2/'}}
        alignment={'center'}
      >
        <h2>Would you like us to implement a similar strategy for you?</h2>
      </TextSection>

      <PrevNext
        previous={{
          title: previous?.data?.title,
          url: previous?.uid && "/insights/" + previous?.uid,
          date: previous?.data?.published_date
        }}
        next={{
          title: next?.data?.title,
          url: next?.uid && "/insights/" + next?.uid,
          date: next?.data?.published_date
        }}
      />
      <PrismicFooter />
    </Layout>
  )

}

export const query = graphql`
    query singleInsight($id: String){
        prismicInsight(id: {eq: $id}) {
            __typename
            _previewable
            data {
                meta_title
                meta_description
                og_image{
                    url
                    gatsbyImageData(layout: FIXED, width: 1200, height: 627)
                }
                published_date
                author{
                    document{
                        ... on PrismicPerson{
                            data{
                                name
                            }
                        }
                    }
                }
                title
                intro_text{
                    html
                }
                featured_image{
                    alt
                    gatsbyImageData(layout: FULL_WIDTH)
                }
                featured_video
                body{
                    __typename
                    ... InsightImage
                    ... InsightText
                    ... InsightImageWithText
                    ... InsightVideo
                    ... InsightHeading
                    ... InsightRawHTML
                }
            }
            uid
            id
            type
            lang
            tags
            alternate_languages {
                id
                lang
            }
        }
    }
`

Insight.query = query

export default withPrismicPreview(Insight)
