import * as React from "react"
import { graphql } from "gatsby"
import TestimonialSectionV2 from "../../components/sections/TestimonialV2Section"
import { PrismicRichText } from "@prismicio/react"

const PrismicPageDataBodyTestimonialV2 = ({ primary }) => {
  const testimonial = {
    text: <PrismicRichText field={primary?.text?.richText} />,
    name: primary?.name,
    title: primary?.person_title,
    highlight: primary?.highlight,
    button_url: primary?.button_url?.url ?? "",
    button_text: primary?.button_text ?? "",
  }

  return (
    <TestimonialSectionV2 {...testimonial} />
  )
}
export default PrismicPageDataBodyTestimonialV2

export const query = graphql`
    fragment TestimonialV2 on PrismicPageDataBodyTestimonialV2{
        slice_type
        slice_label
        __typename
        primary{
          text{
              html
              richText
          }
          name
          person_title
          highlight
          button_url{
              url
              target
          }
          button_text
        }
        
    }
`
