import React from "react"
import Container from "../../atoms/Container"
import styled from "styled-components"
import { Composition } from "atomic-layout"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import { gap } from "../../../utils/spacing"
import Button from "../../atoms/Button"

export interface YellowSectionProps {
  overline?: string
  title?: string
  description?: string
  icon?: GatsbyImageProps
  ctaText?: string
  ctaUrl?: string
  removeVerticalSpacing?: boolean
}

const StyledContainer = styled.div`
  background-color: #FFFCEB;
  border: 2px solid rgba(62, 43, 46, 0.75);
  padding: 24px;
`

const StyledOverline = styled.div`
  color: rgba(62, 43, 46, 0.75);
  font-size: 16px;
  margin-bottom: 0;
`

const StyledTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 32px;
  color: #3E2B2E;
`

const StyledDescription = styled.p`
  color: #3E2B2E;
  margin: 0;
  margin-bottom: 8px;
`

const StyledCta = styled(Button)`
  font-weight: bold;
  font-size: 23px;
`

const StyledIcon = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const YellowSection = ({
  overline,
  title,
  description,
  icon,
  ctaText,
  ctaUrl,
  removeVerticalSpacing = false
}: YellowSectionProps) => {
  const innerProps = removeVerticalSpacing ? { style: { margin: "calc(var(--section-spacing) * -0.5) auto" } } : {}
  
  return (
    <Container innerProps={innerProps}>
      <StyledContainer>
        <Composition
          areas={`
            Icon 
            Content
          `}
          areasMd={`
            Icon Content
          `}
          templateCols={'1fr'}
          templateColsMd={'64px 1fr'}
          gap={gap}
        >
          {(Areas) => (
            <>
              <Areas.Icon>
                <StyledIcon>
                  {icon && <GatsbyImage {...icon} />}
                </StyledIcon>
              </Areas.Icon>
              <Areas.Content>
                {overline && <StyledOverline>{overline}</StyledOverline>}
                {title && <StyledTitle>{title}</StyledTitle>}
                {description && <StyledDescription>{description}</StyledDescription>}
                {ctaText && ctaUrl && (
                  <StyledCta isLink to={ctaUrl}>{ctaText}</StyledCta>
                )}
              </Areas.Content>
            </>
          )}
        </Composition>
      </StyledContainer>
    </Container>
  )
}

export default YellowSection
