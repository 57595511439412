import React from "react"
import Container from "../../atoms/Container"
import styled from "styled-components"
import Button from "../../atoms/Button"

const StyledContainer = styled(Container)`
  &.callout p {
    font-size: 1.75rem;
    line-height: 1.4;
  }
`

const TextSection = ({
                       alignment = "left",
                       children = null,
                       className = "",
                       buttonText = "",
                       buttonUrl = {},
                       topMargin = "default",
                       bottomMargin = "default"
                     }) => {
  const buttonType = {
    secondary: className !== "callout",
    primary: className === "callout"
  }

  let bottomMarginMultiplier
  switch (bottomMargin) {
    case "xs":
      bottomMarginMultiplier = -0.75
      break
    case "sm":
      bottomMarginMultiplier = -0.5
      break
    case "default":
    default:
      bottomMarginMultiplier = 1
      break
  }

  let topMarginMultiplier
  switch (topMargin) {
    case "xs":
      topMarginMultiplier = -0.75
      break
    case "sm":
      topMarginMultiplier = -0.5
      break
    case "default":
    default:
      topMarginMultiplier = 1
      break
  }

  const innerProps = {
      style: {
        marginTop: topMarginMultiplier ? `calc(var(--section-spacing) * ${topMarginMultiplier}) ` : 0,
        marginBottom: bottomMarginMultiplier ? `calc(var(--section-spacing) * ${bottomMarginMultiplier}) ` : 0,
        textAlign: alignment
      }
  }
  
  return (
    <StyledContainer className={className} small innerProps={innerProps}>
      {children}
      {buttonText && buttonUrl &&
      <Button to={buttonUrl?.url} target={buttonUrl?.target} {...buttonType}>{buttonText}</Button>
      // <Button className={"hs-cta-trigger-button hs-cta-trigger-button-132946483517"} to={buttonUrl?.url} target={buttonUrl?.target} {...buttonType}>{buttonText}</Button>
      }
    </StyledContainer>
  )
}

export default TextSection
