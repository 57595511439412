import React from "react"
import Container from "../../atoms/Container"
import styled from "styled-components"
import { Composition } from "atomic-layout"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import { gap } from "../../../utils/spacing"
import { Link } from "gatsby"
import Button from "../../atoms/Button"
import Background from "../../molecules/Background"

export interface InsightCalloutSectionProps {
  overline?: string
  title?: React.ReactNode
  description?: React.ReactNode
  image?: GatsbyImageProps
  link?: string
  removeVerticalSpacing?: boolean
}
const StyledCallout = styled.div`
  background-color: #F7F6F7;
  padding: 1.5rem;
`

const StyledOverline = styled.div`
  color: rgba(62, 43, 46, 0.75);
  font-size: 16px;
`

const StyledButton = styled(Button)`
  font-weight: bold;
  font-size: 23px;
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: rgba(62, 43, 46, 0.75);

  p:first-of-type {
    margin-top: 0;
  }

  p{
    font-size: 18px;
  }
`

const StyledImage = styled.div`
  width: 100%;
  max-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  .gatsby-image-wrapper {
    width: 100%;
    overflow: hidden;
  }
`


const InsightCalloutSection = ({
  title,
  description,
  image,
  overline,
  link,
  removeVerticalSpacing = false
}: InsightCalloutSectionProps) => {
  const innerProps = removeVerticalSpacing ? { style: { margin: "calc(var(--section-spacing) * -0.5) auto" } } : {}
  return (
    <Container innerProps={innerProps}>
      <StyledCallout>
        <Composition
          areas={`
            Image 
            Content
          `}
          areasSm={`Image Content`}
          templateCols={'1fr'}
          templateColsSm={'80px 1fr'}
          templateRows={'auto'}
          gap={gap}
        >
          {(Areas) => (
            <>
              <Areas.Image>
                <StyledImage>
                  {image && <GatsbyImage {...image} />}
                </StyledImage>
              </Areas.Image>
              <Areas.Content>
                <StyledContent>
                  {overline && <StyledOverline>{overline}</StyledOverline>}
                  <StyledButton isLink to={link}>{title}</StyledButton>
                  {description}
                </StyledContent>
              </Areas.Content>
            </>
          )}
        </Composition>
      </StyledCallout>
    </Container>
  )
}

export default InsightCalloutSection
